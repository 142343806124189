import React, { useState, useEffect } from 'react';
import styles from './mainSendComponent.module.css';
import { useStateContext } from '../../..';
import ButtonCreateParcel from './ButtonCreateParcel';

const FormForES = () => {
    const [nameES, setNameES] = useState('');
    const [cityES, setCityES] = useState('');
    const [phoneES, setPhoneES] = useState('');
    const { parcelInfo, setParcelInfo } = useStateContext();
    const [commentES, setCommentES] = useState('');

    const handleNameESChange = (e) => {
        setNameES(e.target.value);
    };
    const handleCityESChange = (e) => {
        setCityES(e.target.value);
    };
    const handlePhoneESChange = (e) => {
        const cleanedPhoneNumber = e.target.value.replace(/[^\d+]/g, '');
        setPhoneES(cleanedPhoneNumber);
    };

    useEffect(() => {
        const updateParcelInfo = { ...parcelInfo };
        updateParcelInfo.receiver.Name = nameES;
        updateParcelInfo.receiver.City = cityES;
        updateParcelInfo.receiver.Phone = phoneES;
        setParcelInfo(updateParcelInfo);
    }, [nameES, cityES, phoneES]);

    const commentChange = (e) => {
        setCommentES(e.target.value);
    };
    useEffect(() => {
        const updateParcelInfo = { ...parcelInfo };
        updateParcelInfo.receiver.AdditionalText = commentES;
        setParcelInfo(updateParcelInfo);
    }, [commentES]);
    return (
        <div>
            <div>
                <h5>
                    Кінцевий отримувач. Дані отримувача в країні ЄС для
                    отримання комбопаку (ці дані будуть на стікері на вашому
                    комбопаку)
                </h5>
            </div>

            <div>
                <label htmlFor="nameES">Прізвище Ім'я*</label>
                <br />
                <input
                    type="text"
                    id="nameES"
                    value={nameES}
                    className={styles.input}
                    onChange={handleNameESChange}
                />
            </div>
            <div>
                <label htmlFor="cityES">Район, Округ, Місто*</label>
                <br />
                <input
                    type="text"
                    id="cityES"
                    value={cityES}
                    className={styles.input}
                    onChange={handleCityESChange}
                />
            </div>
            <div>
                <label htmlFor="phoneES">Телефон*</label>
                <br />
                <input
                    placeholder="674433211"
                    type="tel"
                    value={phoneES}
                    id="phoneES"
                    className={styles.input}
                    onChange={handlePhoneESChange}
                />
            </div>
            <div>
                <label htmlFor="comment">
                    {' '}
                    Додатковий текст на стікері (не обов'язково)
                </label>
                <br />
                <input
                    style={{ marginBottom: 'initial' }}
                    type="text"
                    id="comment"
                    className={styles.input}
                    onChange={commentChange}
                />
            </div>
            <ButtonCreateParcel />
        </div>
    );
};

export default FormForES;
