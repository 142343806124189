import React from 'react';
import styles from './dropInfo.module.css';

const DropInfo = ({ el }) => {
    return (
        <div className={styles.background}>
            <div className={styles.header}>
                <div className={styles.name}>ТТН / Відправник</div>
                <div className={styles.comment}> Коментар</div>
                <div className={styles.weight}> Вага</div>
                <div className={styles.price}>Оголошена вартість </div>
                <div className={styles.dateReceived}> Дата отримання</div>
            </div>
            {el.productList.map((e, index) => {
                return (
                    <div key={e.productId} className={styles.main}>
                        <div className={styles.name}>
                            {index + 1}. {e.name}
                        </div>
                        <div className={styles.comment}>{e.userComment}</div>
                        <div className={styles.weight}> {e.weight} кг</div>
                        <div className={styles.price}> {e.price} грн</div>
                        <div className={styles.dateReceived}>
                            {' '}
                            {e.dateReceived.slice(0, 10)}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default DropInfo;
