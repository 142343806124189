import './loginForm.css'

const Slovo = (props) => {
    return (
     <center>
      <div className="containerr">
        <div className="linee"></div>
        <span className="textt">{props.text}</span>
        <div className="linee"></div>
      </div>
     </center>
        
    );
  }

  export default Slovo