import { useContext } from 'react';
import { Context } from '../..';
import {
    GoogleAuthProvider,
    signInWithRedirect,
    getRedirectResult,
} from 'firebase/auth';
import { useNavigate, Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';

import './loginForm.css';

const GoogleBtn = () => {
    const { auth } = useContext(Context);
    const [user] = useAuthState(auth);
    const navigate = useNavigate();

    const login = async () => {
        navigate('/dashboard');

        const provider = new GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');
        try {
            await signInWithRedirect(auth, provider);
            const result = await getRedirectResult(auth);
            if (result) {
                // This is the signed-in user
                const user = result.user;

                // This gives you a Google Access Token.
                const credential =
                    GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };
    return (
        <button className="google-button" onClick={login}>
            <span className="logo"></span>Продовжити через Google
        </button>
    );
};

export default GoogleBtn;
