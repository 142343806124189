import React, { useState } from 'react';
import sameStyles from './table.module.css';
import styles from './eachParcel.module.css';
import { useStateContext } from '../../..';
import { HiChevronDown } from 'react-icons/hi';
import { HiChevronUp } from 'react-icons/hi';
import DropInfo from './DropInfo';
import PriceDrop from './PriceDrop';
import TtnInfo from './TtnInfo';
const EachParcel = ({ el, isChecked, onCheckboxChange }) => {
    const { setChosenParcel } = useStateContext();
    const [visible, setVisible] = useState(true);
    const [priceIcon, setPriceIcon] = useState(true);
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const handleCheckboxChange = () => {
        onCheckboxChange(!isChecked);
        if (!isChecked) {
            setChosenParcel((prevChosenResponse) => [
                ...prevChosenResponse,
                el,
            ]);
        } else {
            // Если чекбокс снят, удаляем объект из chosenParcel
            setChosenParcel((prevChosenResponse) =>
                prevChosenResponse.filter((item) => item !== el)
            );
        }
    };
    const ttn = `${el.statusUpdateDate}: ${el.npStatus}`;
    return (
        <div>
            <div className={sameStyles.main}>
                <div className={sameStyles.container}>
                    <div className={sameStyles.check}>
                        <input
                            type="checkbox"
                            checked={isChecked}
                            className={sameStyles.checkbox}
                            onChange={handleCheckboxChange}
                        />
                    </div>
                    <div className={sameStyles.dateCreating}>
                        {el.creationDate.slice(0, 10)}
                    </div>

                    <div className={sameStyles.ttn}>
                        <span
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            {el.ttn}
                        </span>
                        {isHovered && <TtnInfo el={el} />}

                        <span
                            onClick={() => {
                                setVisible(!visible);
                                setPriceIcon(true);
                            }}
                        >
                            {visible ? (
                                <HiChevronDown
                                    className={styles.iconDropDown}
                                />
                            ) : (
                                <HiChevronUp className={styles.iconDropDown} />
                            )}
                        </span>
                    </div>
                    <div className={sameStyles.statusStor}>
                        <b>{el.status}</b> <br />
                    </div>
                    <div className={sameStyles.sender}>
                        <b>{el.agent.name}</b>
                        <br />
                        <span
                            className={sameStyles.alert}
                            data-title={el.agent.novaPoshtaSettlement}
                        >
                            {el.agent.novaPoshtaSettlement}
                        </span>
                        <br />
                        <span
                            className={sameStyles.alert}
                            data-title={el.agent.novaPoshtaWarehouse}
                        >
                            {el.agent.novaPoshtaWarehouse}
                        </span>
                        <br />
                        <span className={sameStyles.phone}>
                            {' '}
                            {el.agent.phone}
                        </span>
                    </div>

                    <div className={sameStyles.receiver}>
                        <b>{el.receiver.name} </b> <br />
                        {el.receiver.city}
                        <br />
                        <span className={sameStyles.phone}>
                            {' '}
                            {el.receiver.phone}
                        </span>
                    </div>

                    <div className={sameStyles.title}>{el.comment}</div>

                    <div className={sameStyles.volumeWeightNp}>
                        {el.weight} кг
                    </div>

                    <div className={sameStyles.Price}>
                        {!el.paid && !el.needToPay ? undefined : (
                            <div>
                                <span>
                                    <span
                                        style={{
                                            color: '#006645',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {el.paid}€
                                    </span>{' '}
                                    /{' '}
                                    <span
                                        style={{
                                            color: '#FF5631',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {el.needToPay}€
                                    </span>
                                </span>
                                <span
                                    onClick={() => {
                                        setPriceIcon(!priceIcon);
                                        setVisible(true);
                                    }}
                                >
                                    {priceIcon ? (
                                        <HiChevronDown
                                            className={styles.iconDropDown}
                                        />
                                    ) : (
                                        <HiChevronUp
                                            className={styles.iconDropDown}
                                        />
                                    )}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                {!visible ? <DropInfo el={el} /> : undefined}
                {!priceIcon ? <PriceDrop el={el} /> : undefined}

                <div className={sameStyles.line}></div>
            </div>
        </div>
    );
};

export default EachParcel;
