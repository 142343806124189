import React, { useEffect } from 'react';
import { useStateContext } from '../../..';
import { FaWallet } from 'react-icons/fa';
import styles from './payComponent.module.css';

const PayComponent = ({ chosenParcelLehgth, checkZeroNeedToPay }) => {
    const { payPostResponse, setPayPostResponse, chosenParcel } =
        useStateContext();

    useEffect(() => {
        if (payPostResponse) {
            // Создаем парсер для разбора HTML-разметки
            const parser = new DOMParser();
            const doc = parser.parseFromString(payPostResponse, 'text/html');

            // Находим форму
            const form = doc.querySelector('form');

            if (form) {
                // Создаем кнопку как DOM-узел
                const submitButton = document.createElement('button');
                submitButton.type = 'submit';
                submitButton.textContent = 'Сплатити виділене';
                submitButton.classList.add(
                    !chosenParcelLehgth ||
                        checkZeroNeedToPay ||
                        chosenParcelLehgth > 10
                        ? styles.HiddenButton
                        : styles.ActiveButton
                ); // Применяем стили

                // Заменяем элемент <input type="image"> на кнопку
                const imageInput = form.querySelector('input[name="btn_text"]');
                if (imageInput && imageInput.parentNode) {
                    imageInput.parentNode.replaceChild(
                        submitButton,
                        imageInput
                    );
                }
            }

            // Обновляем состояние с измененной разметкой
            setPayPostResponse(doc.documentElement.outerHTML);
        }
    }, [payPostResponse, chosenParcel]);

    return (
        <>
            {payPostResponse ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <FaWallet
                        className={
                            !chosenParcelLehgth ||
                            checkZeroNeedToPay ||
                            chosenParcelLehgth > 10
                                ? styles.HiddenIcon
                                : styles.ActiveIcon
                        }
                    />
                    <span
                        className={
                            !chosenParcelLehgth ||
                            checkZeroNeedToPay ||
                            chosenParcelLehgth > 10
                                ? styles.HiddenSpanCursor
                                : styles.ActiveSpanCursor
                        }
                        dangerouslySetInnerHTML={{
                            __html: payPostResponse,
                        }}
                    ></span>
                </div>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'not-allowed',
                        opacity: '0.5',
                    }}
                >
                    <FaWallet className={styles.icon} />
                    <span> Сплатити виділене</span>
                </div>
            )}
        </>
    );
};

export default PayComponent;
