import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './mainSendComponent.module.css';
import { useStateContext } from '../../..';

const FormForUkr = () => {
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [cityRefMap, setCityRefMap] = useState({}); //
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState('');
    const [nameUA, setNameUA] = useState('');
    const [phoneUA, setPhoneUA] = useState('');
    const { parcelInfo, setParcelInfo } = useStateContext();
    const { setCheckCity, setCheckAddress } = useStateContext();
    useEffect(() => {
        axios
            .post('https://api.novaposhta.ua/v2.0/json/', {
                apiKey: '40627bcbc00bfa0931c82a520124ad0b',
                modelName: 'Address',
                calledMethod: 'searchSettlements',
                methodProperties: {
                    CityName:
                        selectedCity.length > 18
                            ? selectedCity.slice(0, 18)
                            : selectedCity,
                    Limit: '20',
                    Page: '1',
                },
            })
            .then((response) => {
                const cityData = response.data.data[0].Addresses;
                const cityRefMapping = {};
                cityData.forEach((city) => {
                    cityRefMapping[city.Present] = city.Ref;
                    cityRefMapping[city.Present + 'DeliveryCity'] =
                        city.DeliveryCity;
                });
                setCityRefMap(cityRefMapping);

                setCities(cityData.map((address) => address.Present));
                const updateParcelInfo = { ...parcelInfo };
                updateParcelInfo.agent.Name = nameUA;
                updateParcelInfo.agent.NovaPoshtaSettlementRef =
                    cityRefMap[selectedCity + 'DeliveryCity'];
                updateParcelInfo.agent.Phone = phoneUA;

                setParcelInfo(updateParcelInfo);
            })
            .catch((error) => {
                console.error('Error fetching cities:', error);
            });
    }, [selectedCity, phoneUA, nameUA]);

    useEffect(() => {
        setSelectedBranch('');
    }, [selectedCity]);

    const handleUrkNameChange = (e) => {
        setNameUA(e.target.value);
    };

    const handlePhoneChange = (e) => {
        const cleanedPhoneNumber = e.target.value.replace(/[^\d+]/g, '');
        setPhoneUA(cleanedPhoneNumber);
    };

    const handleCityChange = (event) => {
        const selectedCity = event.target.value;
        setSelectedCity(selectedCity);
    };
    // нужно сделать проверку если ли в объекте cityRefMap ключ с именем selectedCity
    useEffect(() => {
        setCheckCity(false);
        if (cityRefMap.hasOwnProperty(selectedCity)) {
            setCheckCity(true);
        }
    }, [selectedCity, cityRefMap]);

    useEffect(() => {
        if (cityRefMap[selectedCity] && selectedCity.length > 3) {
            axios
                .post('https://api.novaposhta.ua/v2.0/json/', {
                    apiKey: '40627bcbc00bfa0931c82a520124ad0b',
                    modelName: 'Address',
                    calledMethod: 'getWarehouses',
                    methodProperties: {
                        FindByString: '',
                        SettlementRef: cityRefMap[selectedCity],
                    },
                })
                .then((response) => {
                    const branchNames = response.data.data.map((branch) => {
                        if (!branch.Description.includes('Поштомат')) {
                            return branch.Description;
                        }
                    });
                    setBranches(branchNames);
                    response.data.data.forEach((el) => {
                        if (el.Description === selectedBranch) {
                            const updateParcelInfo = { ...parcelInfo };
                            updateParcelInfo.agent.NovaPoshtaWarehouseRef =
                                el.Ref;
                            setParcelInfo(updateParcelInfo);
                        }
                        if (!branches.includes(selectedBranch)) {
                            const updateParcelInfo = { ...parcelInfo };
                            updateParcelInfo.agent.NovaPoshtaWarehouseRef = '';
                            setParcelInfo(updateParcelInfo);
                        }
                    });
                })
                .catch((error) => {
                    console.error('Error fetching branches:', error);
                });
        }
    }, [cityRefMap, selectedCity, selectedBranch]);

    // нужно сделать проверку что selectedBranch есть в branches

    useEffect(() => {
        setCheckAddress(false);
        if (branches.includes(selectedBranch)) {
            setCheckAddress(true);
        }
    }, [selectedBranch, branches]);

    return (
        <div>
            <div style={{ marginBottom: '32px' }}>
                <h5> Ваш посередник в Україні, який доставляє в вашу країну</h5>
            </div>
            <div>
                <label htmlFor="inputName">Прізвище Ім'я*</label>
                <br />
                <input
                    placeholder="Тарас Шевченко"
                    type="text"
                    id="inputName"
                    className={styles.input}
                    onChange={handleUrkNameChange}
                />
            </div>

            <div>
                <label>Населений пункт*</label>
                <br />
                <input
                    type="text"
                    value={selectedCity}
                    className={styles.input}
                    onChange={handleCityChange}
                    list="city-list"
                />
                <datalist id="city-list">
                    {cities.map((city, index) => (
                        <option key={index} value={city} />
                    ))}
                </datalist>
            </div>
            <div>
                <label>Відділення Нової Пошти*</label>
                <br />
                <input
                    type="text"
                    value={selectedBranch}
                    className={styles.input}
                    onChange={(e) => setSelectedBranch(e.target.value)}
                    list="adress-list"
                />
                <datalist id="adress-list">
                    {branches.map((branch, index) => (
                        <option key={index} value={branch}>
                            {branch}
                        </option>
                    ))}
                </datalist>
            </div>
            <div>
                <label htmlFor="phone">Телефон*</label>
                <br />
                <input
                    style={{ marginBottom: '1px' }}
                    type="tel"
                    placeholder="0504123297"
                    id="phone"
                    value={phoneUA}
                    className={styles.input}
                    onChange={handlePhoneChange}
                />
            </div>
            <div className={styles.mobline}></div>
        </div>
    );
};

export default FormForUkr;
