import './loginForm.css'

const ErrorComp = (props) =>{
    const {text} = props
    let newText = ' '
if (text ==='Firebase: Error (auth/wrong-password).') {newText='Неправильний пароль'}
if (text ==='Firebase: Error (auth/user-not-found).') {newText='Акаунт не знайдено'}
if (text ==='Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).') {newText='Доступ до цього акаунту тимчасово заблоковано'}
if (text ==='Firebase: Password should be at least 6 characters (auth/weak-password).') {newText='Пароль має бути не менше 6 символів'}
if (text ==='Firebase: Error (auth/email-already-in-use).') {newText='Цей Email вже використовується'}

return <p className="errorText">{newText}</p>
}

export default ErrorComp


