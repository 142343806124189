import axios from 'axios';

const postRequest = async (
    ttn,
    email,
    coment,
    setResponse,
    setError,
    navigator
) => {
    const token =
        'wrYl_zl_8dLXaZul7GcfpqmDqr7jEnli7or_zct_ETxJnOa4ddaEzftNXbuvNSBQn';
    try {
        await axios.post(
            `https://europe-central2-ukrsklad-4bb35.cloudfunctions.net/product`,
            {
                user_email: email,
                ttn: ttn,
                user_comment: coment,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        await axios
            .get(
                `https://europe-central2-ukrsklad-4bb35.cloudfunctions.net/product?user_email=${email}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(function (res) {
                setResponse(res.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
        navigator('/dashboard/parcels');
    } catch (error) {
        setError(error.response.data.errorMessage);
    }
};

export default postRequest;
