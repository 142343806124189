import './loginForm.css';
import React, { useContext } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import LoginForm from './LoginForm';
import { Context } from '../..';
import { Link } from 'react-router-dom';
import Slovo from './Slovo';
import GoogleBtn from './GoogleBtn';
import Loader from './Loader';
import { Navigate } from 'react-router-dom';
const LoginComp = () => {
    const { auth } = useContext(Context);
    const [user, loading] = useAuthState(auth);
    if (loading) {
        return <Loader />;
    }
    return !user ? (
        <div>
            <div className="logotip">
                <img src="./1.png" className="logotip" alt="logo" />
            </div>
            <div style={{ padding: '10px' }}>
                <LoginForm />
            </div>
            <div style={{ marginTop: '10px' }}>
                <p className="text">
                    Немає акаунту?{' '}
                    <span>
                        <Link to="/registration" className="link">
                            {' '}
                            Створити акаунт{' '}
                        </Link>
                    </span>
                </p>
            </div>
            <div style={{ padding: '10px' }}>
                <Slovo text="або" />
            </div>
            <div className="mybtn">
                <GoogleBtn />
            </div>
        </div>
    ) : (
        <Navigate to="/dashboard" />
    );
};

export default LoginComp;
