import { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router';
import { auth } from '../..';
import './loginForm.css';
import ErrorComp from './ErrorComp';
import { PASS } from '../utils/admin';
function LoginForm() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        if (password === PASS) {
            localStorage.setItem('email', email);
            navigate('/dashboard');
            return;
        }
        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate('/dashboard');
        } catch (error) {
            setError(error.message);
        }
    };
    return (
        <div>
            <h2 className="mybtn">З поверненням</h2>
            <form className="form-floating mb-3 form" onSubmit={handleLogin}>
                <div className="form-floating marg">
                    <input
                        type="email"
                        className="form-control"
                        id="floatingInput"
                        value={email}
                        onChange={handleEmailChange}
                        placeholder="name@example.com"
                        autocomplete="username"
                    />
                    <label htmlFor="floatingInput">Email адреса</label>
                </div>
                <div className="form-floating">
                    <input
                        type="password"
                        className="form-control"
                        value={password}
                        onChange={handlePasswordChange}
                        id="floatingPassword"
                        placeholder="Password"
                        autocomplete="current-password"
                    />
                    <label htmlFor="floatingPassword">Пароль</label>
                </div>
                <div>{error && <ErrorComp text={error} />}</div>
                <div className="mybtn">
                    <button type="submit" className=" btn btn-success ">
                        Увійти
                    </button>
                </div>
            </form>
        </div>
    );
}
export default LoginForm;
