import React from 'react';
import './private.css';
import SideBar from '../sidebar/SideBar';
import Header from '../heder/Header';
import MainProduct from '../mainProduct/MainProduct';
import { useStateContext } from '../../..';
import { Navigate } from 'react-router-dom';
const Products = () => {
    const { mobVisibleSideBar } = useStateContext();

    return localStorage.getItem('email') ? (
        <div className="app">
            <SideBar />
            <div className="headerAndMain">
                <Header />
                <main
                    className={
                        mobVisibleSideBar ? 'mobMainContent' : 'main-content'
                    }
                >
                    <MainProduct />
                </main>
            </div>
        </div>
    ) : (
        <Navigate to="/registration" />
    );
};

export default Products;
