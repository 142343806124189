import React from 'react';
import { Navigate } from 'react-router-dom';
import './private.css';
import SideBar from '../sidebar/SideBar';
import Header from '../heder/Header';
import MainSendComponent from '../mainSendParcel/MainSendComponent';
import { useStateContext } from '../../..';

const SendParcel = () => {
    const { mobVisibleSideBar } = useStateContext();
    const { response } = useStateContext();

    if (response.length === 0) {
        return <Navigate to="/dashboard/parcels" />;
    }

    return localStorage.getItem('email') ? (
        <div className="app">
            <SideBar />
            <div className="headerAndMain">
                <Header />
                <main
                    className={
                        mobVisibleSideBar ? 'mobMainContent' : 'main-content'
                    }
                >
                    <MainSendComponent />
                </main>
            </div>
        </div>
    ) : (
        <Navigate to="/registration" />
    );
};

export default SendParcel;
