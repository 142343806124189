import React from 'react';
import styles from './hederForSendComponent.module.css';

const EveryProductForSendComponent = ({ el }) => {
    return (
        <div className={styles.main}>
            <div className={styles.container}>
                <div className={styles.data}>
                    {el.dateCreatedNp.slice(0, 10).replace(/-/g, '.')}
                </div>
                <div className={styles.ttn}>{el.ttn}</div>
                <div className={styles.senderNameNp}>{el.senderNameNp}</div>
                <div className={styles.titleNp}>{el.titleNp}</div>
                <div className={styles.userComment}>{el.userComment}</div>
                <div className={styles.volumeWeightNp}>
                    {el.volumeWeightNp} кг
                </div>
                <div className={styles.announcedPrice}>
                    {el.announcedPrice} грн
                </div>

                <div
                    className={styles.statusNp}
                    style={{ color: 'green', fontWeight: '600' }}
                >
                    {el.statusNp}
                </div>
                <div className={styles.dateReceivedNp}>
                    {el.dateReceivedNp.slice(0, 10)}
                </div>
            </div>
            <div className={styles.line}></div>
        </div>
    );
};

export default EveryProductForSendComponent;
