import React, { useState, useEffect } from 'react';
import styles from './pageWithProducts.module.css';
import EveryProduct from './EveryProduct';
import HeaderProductList from './HeaderProductList';
import { useStateContext } from '../../../..';
import { useNavigate } from 'react-router';
import { prices } from '../../../utils/prices';

const PageWithProducts = ({ response }) => {
    const activeResponse = response.filter((el) => el.isActive);
    const navigator = useNavigate();
    const [checkboxes, setCheckboxes] = useState(
        Array(response.length).fill(false)
    );
    const [productStatus, setProductStatus] = useState(false);

    const { chosenResponse, setСhosenResponse } = useStateContext();

    useEffect(() => {
        // Очищаем chosenResponse при смене URL
        setСhosenResponse([]);
    }, [navigator, setСhosenResponse]);

    const handleCheckboxChange = (index, checked) => {
        const updatedCheckboxes = [...checkboxes];
        updatedCheckboxes[index] = checked;
        setCheckboxes(updatedCheckboxes);
    };
    const handleAllCheck = (checked) => {
        const updatedCheckboxes = Array(response.length).fill(checked);
        setCheckboxes(updatedCheckboxes);
        if (checked) {
            setСhosenResponse(activeResponse);
        } else {
            // Если чекбокс "Выделить все" снят, очищаем chosenResponse
            setСhosenResponse([]);
        }
    };

    useEffect(
        () =>
            setProductStatus(
                chosenResponse.some(
                    (el) => el.statusNp !== 'Відправлення отримано'
                )
            ),
        [chosenResponse]
    );

    const cereateParcel = () => {
        if (chosenResponse.length === 0 || productStatus) {
            return;
        }

        if (!productStatus) {
            navigator('/dashboard/create-combopack');
        }
    };

    return (
        <>
            <div className={styles.main}>
                <HeaderProductList setAllCheckboxes={handleAllCheck} />

                {response.map((el, index) => {
                    return (
                        <div key={index} className={styles.relative}>
                            {' '}
                            <EveryProduct
                                el={el}
                                isChecked={checkboxes[index]}
                                onCheckboxChange={(checked) =>
                                    handleCheckboxChange(index, checked)
                                }
                            />
                        </div>
                    );
                })}
                {productStatus ? (
                    <div className={styles.divBtn}>
                        <p className={styles.text}>
                            Одина або кілька з вибраних посилок ще не надійшли
                            на склад
                        </p>
                    </div>
                ) : undefined}
                <div className={styles.divBtn}>
                    <button
                        className={`${styles.btn} ${
                            chosenResponse.length === 0 || productStatus
                                ? styles.disabledButton
                                : ''
                        }`}
                        onClick={cereateParcel}
                    >
                        Створити комбопак
                        <br />
                        {chosenResponse.length === 0 ? (
                            <span className={styles.smallText}>
                                (виберіть посилки)
                            </span>
                        ) : undefined}
                    </button>
                </div>
                <span className={styles.infoText}>
                    <p>
                        <span style={{ color: '#239dde', fontSize: '26px' }}>
                            *
                        </span>
                        Посилки будуть зберігатися на складі безкоштовно
                        протягом 7 днів. Далі вартість зберігання буде коштувати{' '}
                        {prices.storageAfterSevenDays}€ за добу за одну посилку.
                    </p>
                </span>
            </div>
        </>
    );
};

export default PageWithProducts;
