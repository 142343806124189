import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { auth } from '../..';
import './loginForm.css';
import ErrorComp from './ErrorComp';

const RegForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {
            await createUserWithEmailAndPassword(auth, email, password);
            navigate('/dashboard');
            // Дополнительные действия после успешной регистрации
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div>
            <h2 className="mybtn">Створити акаунт </h2>
            <form
                className="form-floating mb-3 form"
                onSubmit={handleFormSubmit}
            >
                <div className="form-floating marg">
                    <input
                        type="email"
                        className="form-control "
                        id="floatingInput"
                        value={email}
                        onChange={handleEmailChange}
                        placeholder="name@example.com"
                        autocomplete="username"
                    />
                    <label htmlFor="floatingInput">Email адреса</label>
                </div>
                <div className="form-floating">
                    <input
                        type="password"
                        className="form-control"
                        value={password}
                        onChange={handlePasswordChange}
                        id="floatingPassword"
                        placeholder="Password"
                        autocomplete="current-password"
                    />
                    <label htmlFor="floatingPassword">Пароль</label>
                </div>
                <div>{error && <ErrorComp text={error} />}</div>
                <div className="mybtn">
                    <button type="submit" className=" btn btn-success ">
                        Реєстрація
                    </button>
                </div>
            </form>
        </div>
    );
};

export default RegForm;
