import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './buttonPay.module.css';
import { useStateContext } from '../../..';
import PayComponent from '../mainPay/PayComponent';
const ButtonPay = () => {
    const { chosenParcel, setPayPostResponse } = useStateContext();
    const [error, setError] = useState('');
    const [checkZeroNeedToPay, setCheckZeroNeedToPay] = useState(false);
    const email = localStorage.getItem('email');

    useEffect(() => {
        if (chosenParcel.length === 0) {
            setPayPostResponse(null);
        }
        if (chosenParcel.length > 0 && chosenParcel.length <= 10) {
            const postArray = chosenParcel.map((el) => ({
                id: el.id,
                price: el.needToPay,
            }));
            (async function () {
                const token =
                    'wrYl_zl_8dLXaZul7GcfpqmDqr7jEnli7or_zct_ETxJnOa4ddaEzftNXbuvNSBQn';
                try {
                    const payResponse = await axios.post(
                        `https://europe-central2-ukrsklad-4bb35.cloudfunctions.net/payment_form`,
                        {
                            user_email: email,
                            parcel_price_list: postArray,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    setCheckZeroNeedToPay(
                        chosenParcel.some((el) => el.needToPay === 0)
                    );

                    setPayPostResponse(payResponse.data.data);
                } catch (error) {
                    setError(error.response.data.errorMessage);
                }
            })();
        }
    }, [chosenParcel]);
    return (
        <div>
            <div className={styles.btn}>
                <PayComponent
                    chosenParcelLehgth={chosenParcel.length}
                    checkZeroNeedToPay={checkZeroNeedToPay}
                />
            </div>
            {error && (
                <div className={styles.error}>
                    Під час відправлення запиту трапилась помилка
                </div>
            )}
        </div>
    );
};

export default ButtonPay;
