import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import LoginComp from './components/singIn_or_logIn/LoginComp';
import Dashboard from './components/cabinet/pages/Dashboard';
import Main from './components/singIn_or_logIn/Main';
import RegComp from './components/singIn_or_logIn/RegComp';
import Loader from './components/singIn_or_logIn/Loader';
import Products from './components/cabinet/pages/Products.js';
import Profile from './components/cabinet/pages/Profile';
import CreateProduct from './components/cabinet/pages/CreateProduct';
import SendParcel from './components/cabinet/pages/SendParcel';
import Parcel from './components/cabinet/pages/Parcel';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/dashboard/parcels" element={<Products />} />
                <Route path="/dashboard/profile" element={<Profile />} />
                <Route path="/dashboard/combopacks" element={<Parcel />} />
                <Route
                    path="/dashboard/parcels/create-parcel"
                    element={<CreateProduct />}
                />
                <Route
                    path="/dashboard/create-combopack"
                    element={<SendParcel />}
                />
                <Route path="/registration" element={<RegComp />} />
                <Route path="/login" element={<LoginComp />} />
                <Route path="/" element={<Main />} />
                <Route path="/loading" element={<Loader />} />
                <Route path="*" element={<Navigate to="registration" />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
