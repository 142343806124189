import React from 'react';
import styles from './hederForSendComponent.module.css';

const HederForSendComponent = () => {
    return (
        <div>
            <div className={styles.header}>
                <div className={styles.data}>Дата створення</div>
                <div className={styles.ttn}> ТТН</div>
                <div className={styles.senderNameNp}> Відправник</div>
                <div className={styles.titleNp}> Опис відправлення</div>
                <div className={styles.userComment}>Коментар </div>
                <div className={styles.volumeWeightNp}> Вага </div>
                <div className={styles.announcedPrice}>Оголошена вартість</div>
                <div className={styles.statusNp} style={{ fontWeight: '700' }}>
                    {' '}
                    Статус
                </div>
                <div className={styles.dateReceivedNp}> Дата отримання</div>
            </div>
        </div>
    );
};

export default HederForSendComponent;
