import React, { createContext, useContext, useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: 'AIzaSyCRXtcArDQ6Y0gT8YYK1IpmZNGDBwPlBjM',
    authDomain: 'ukrsklad-4bb35.firebaseapp.com',
    projectId: 'ukrsklad-4bb35',
    storageBucket: 'ukrsklad-4bb35.appspot.com',
    messagingSenderId: '605644805834',
    appId: '1:605644805834:web:7247950f4522859e6c4633',
    measurementId: 'G-7J2GPNPVFJ',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);

const db = getFirestore(app);

onAuthStateChanged(auth, (user) => {
    if (user !== null) {
        console.log('logged in');
        localStorage.setItem('email', user.email);
    } else {
        console.log('no user');
    }
});
// начало ахуя
export const StateContext = createContext();

export const StateProvider = ({ children }) => {
    const [response, setResponse] = useState([]);
    const [parcelResponse, setParcelResponse] = useState([]);
    const [loader, setLoader] = useState(false);
    const [chosenResponse, setСhosenResponse] = useState([]);
    const [chosenParcel, setChosenParcel] = useState([]);
    const [parcelInfo, setParcelInfo] = useState({
        userEmail: '',
        productsList: [],
        agent: {
            Name: '',
            NovaPoshtaSettlementRef: '',
            NovaPoshtaWarehouseRef: '',
            Phone: '',
        },
        receiver: {
            Name: '',
            City: '',
            Phone: '',
            AdditionalText: '',
        },
        comment: '',
    });
    const [checkCity, setCheckCity] = useState(false);
    const [checkAddress, setCheckAddress] = useState(false);
    const [mobVisibleSideBar, setMobVisibleSideBar] = useState(false);
    const [postParcelResponse, setPostParcelResponse] = useState(null);
    const [payPostResponse, setPayPostResponse] = useState(null);
    useEffect(() => {
        setParcelInfo((prevParcelInfo) => ({
            ...prevParcelInfo,
            userEmail: localStorage.getItem('email'),
        }));
    }, [localStorage.getItem('email')]);
    return (
        <StateContext.Provider
            value={{
                response,
                setResponse,
                parcelResponse,
                setParcelResponse,
                loader,
                setLoader,
                chosenResponse,
                setСhosenResponse,
                parcelInfo,
                setParcelInfo,
                checkCity,
                setCheckCity,
                checkAddress,
                setCheckAddress,
                mobVisibleSideBar,
                setMobVisibleSideBar,
                chosenParcel,
                setChosenParcel,
                postParcelResponse,
                setPostParcelResponse,
                payPostResponse,
                setPayPostResponse,
            }}
        >
            {children}
        </StateContext.Provider>
    );
};
export const useStateContext = () => useContext(StateContext);
// конец ахуя
export const Context = createContext(null);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Context.Provider value={{ auth, db }}>
        <StateProvider>
            <App />
        </StateProvider>
    </Context.Provider>
);
