import React, { useEffect, useState } from 'react';
import { useStateContext } from '../../..';
import { BiChevronLeft } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import styles from './mainSendComponent.module.css';
import HederForSendComponent from './HederForSendComponent';
import EveryProductForSendComponent from './EveryProductForSendComponent';
import FormForUkr from './FormForUkr';
import FormForES from './FormForES';
import { FaInfo } from 'react-icons/fa';

const MainSendComponent = () => {
    const navigator = useNavigate();
    const { chosenResponse } = useStateContext();
    const { parcelInfo, setParcelInfo } = useStateContext();
    const [parcelWeight, setParcelWeight] = useState(0);
    const [parcelPrice, setParcelPrice] = useState(0);
    const [comment, setComment] = useState('');

    useEffect(() => {
        parcelInfo.productsList = [];
        const updateParcelInfo = { ...parcelInfo };
        setParcelInfo(updateParcelInfo);
        chosenResponse.forEach((el) => {
            updateParcelInfo.productsList.push(el.id);
        });
        setParcelInfo(updateParcelInfo);
    }, [chosenResponse]);

    useEffect(() => {
        let sum = 0;
        chosenResponse.forEach((el) => {
            sum += el.volumeWeightNp;
        });
        setParcelWeight(sum);
    }, [chosenResponse]);

    useEffect(() => {
        let sum = 0;
        chosenResponse.forEach((el) => {
            sum += el.announcedPrice;
        });
        setParcelPrice(sum);
    }, [chosenResponse]);

    const toPreviousPage = () => {
        navigator('/dashboard/parcels');
    };

    const commentChange = (e) => {
        setComment(e.target.value);
    };
    useEffect(() => {
        const updateParcelInfo = { ...parcelInfo };
        updateParcelInfo.comment = comment;
        setParcelInfo(updateParcelInfo);
    }, [comment]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            // Выполните здесь необходимые действия перед обновлением страницы
            // Например, сохраните данные в локальном хранилище или отправьте их на сервер

            e.preventDefault();
            e.returnValue = ''; // Это предотвратит закрытие страницы без подтверждения
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            // Удалите слушателя события перед размонтированием компонента
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <div>
            <div onClick={toPreviousPage} className={styles.toPreviousPage}>
                <BiChevronLeft className={styles.icon} />
                <span className={styles.comeBackText}>
                    Повернутися до списку посилок
                </span>
            </div>
            <div>
                <h4>Оформлення комбопаку</h4>
            </div>
            <div className={styles.spasiboNeNado}>
                <HederForSendComponent />
            </div>
            {chosenResponse.map((el, index) => {
                return (
                    <div key={index}>
                        <EveryProductForSendComponent el={el} />
                    </div>
                );
            })}
            <div className={styles.parcelWeight}>
                <p> Загальна оголошена вартість: {parcelPrice} грн</p>
            </div>
            <div className={styles.parcelWeight}>
                <p> Загальна вага: {parcelWeight} кг</p>
            </div>

            {/* <div className={styles.sukloText}>
                <div className={styles.infoIcon}>
                    <FcInfo />
                </div>
                <div>
                    <p>
                        Ми доставимо посилку вашому перевізнику, який прийме її
                        в Україні Новою поштою та вручить вам у країні ЄС. Ми
                        несемо повну відповідальність за шлях посилки від нашого
                        складу до відділення вашого перевізника. Після отримання
                        посилки перевізником ми не несемо відповідальність за
                        подальший шлях цієї посилки.
                    </p>
                    <div className={styles.line}></div>
                </div>
            </div> */}

            <div className={styles.commentText}>
                <label htmlFor="commentt">
                    {' '}
                    Назвіть ваш комбопак (цей текст бачитимите тількі ви)
                </label>

                <input
                    type="text"
                    id="commentt"
                    className={styles.largeInput}
                    onChange={commentChange}
                />
            </div>
            <div className={styles.nexline}></div>

            <div className={styles.container}>
                <div className={styles.eachForm}>
                    <FormForUkr />
                </div>

                <div className={styles.eachForm}>
                    <FormForES />
                </div>
            </div>
        </div>
    );
};

export default MainSendComponent;
