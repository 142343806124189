import React, { useState, useEffect } from 'react';
import styles from './everyProduct.module.css';
import { useStateContext } from '../../../..';

const EveryProduct = ({ el, isChecked, onCheckboxChange }) => {
    const { setСhosenResponse } = useStateContext();
    // const [daysPassed, setDaysPassed] = useState(0);
    const [sevenDays, setSevenDays] = useState(new Date());

    useEffect(() => {
        if (el.dateReceivedNp) {
            // Преобразование строки с датой в объект Date
            const dateString = el.dateReceivedNp;
            const dateParts = dateString.split(' '); // Разбиваем строку на дату и время
            const datePart = dateParts[0].split('.'); // Разбиваем дату на части
            const timePart = dateParts[1].split(':');
            const targetDate = new Date(
                parseInt(datePart[2], 10), // Год
                parseInt(datePart[1], 10) - 1, // Месяц (нумерация месяцев начинается с 0)
                parseInt(datePart[0], 10), // День
                parseInt(timePart[0], 10), // Часы
                parseInt(timePart[1], 10), // Минуты
                parseInt(timePart[2], 10) // Секунды
            );

            // Получение текущей даты и времени
            // const currentDate = new Date();

            // Вычисление разницы между датами в миллисекундах
            // const timeDifference = currentDate - targetDate;

            // Вычисление количества прошедших суток
            // const days = Math.floor(timeDifference / (24 * 60 * 60 * 1000) - 7);

            // setDaysPassed(days);
            const sevenDaysLater = new Date(targetDate);
            sevenDaysLater.setDate(sevenDaysLater.getDate() + 7);
            setSevenDays(sevenDaysLater);
        }
    }, [el.dateReceivedNp]);

    const handleCheckboxChange = () => {
        onCheckboxChange(!isChecked);
        if (!isChecked) {
            setСhosenResponse((prevChosenResponse) => [
                ...prevChosenResponse,
                el,
            ]);
        } else {
            // Если чекбокс снят, удаляем объект из chosenResponse
            setСhosenResponse((prevChosenResponse) =>
                prevChosenResponse.filter((item) => item !== el)
            );
        }
    };
    const formattedSevenDays = sevenDays
        .toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        })
        .replace(/\//g, '.');
    const dateComponents = formattedSevenDays.split('.');

    // Меняем местами день и месяц
    const [day, month, year] = dateComponents;
    const formattedDate = `${month}.${day}.${year}`;

    return el.isActive ? (
        <div className={styles.main}>
            <div className={styles.container}>
                <div className={styles.check}>
                    <input
                        type="checkbox"
                        checked={isChecked}
                        className={styles.checkbox}
                        onChange={handleCheckboxChange}
                    />
                </div>
                <div className={styles.data}>
                    {el.dateCreatedNp.slice(0, 10).replace(/-/g, '.')}
                </div>
                <div className={styles.ttn}>{el.ttn}</div>
                <div className={styles.senderNameNp}>{el.senderNameNp}</div>
                <div className={styles.titleNp}>{el.titleNp}</div>
                <div className={styles.userComment}>{el.userComment}</div>
                <div className={styles.volumeWeightNp}>
                    {el.volumeWeightNp} кг
                </div>
                <div className={styles.announcedPrice}>
                    {el.announcedPrice} грн
                </div>
                <div
                    className={styles.statusNp}
                    style={
                        el.statusNp === 'Відправлення отримано'
                            ? { color: 'green' }
                            : { color: '#239dde' }
                    }
                >
                    {el.statusNp}
                </div>
                <div className={styles.dateReceivedNp}>
                    {el.dateReceivedNp ? el.dateReceivedNp.slice(0, 10) : null}
                </div>
                <div className={styles.dateCount}>
                    {el.dateReceivedNp ? formattedDate : null}
                </div>
            </div>
            <div className={styles.line}></div>
        </div>
    ) : null;
};

export default EveryProduct;
