import React from 'react';
import { useNavigate } from 'react-router';
import { IoIosAddCircleOutline } from 'react-icons/io';
import styles from './pageWithProducts.module.css';
import sameStyles from './everyProduct.module.css';
import { BsInfoCircle } from 'react-icons/bs';
import { prices } from '../../../utils/prices';

const HeaderProductList = ({ setAllCheckboxes }) => {
    const navigator = useNavigate();
    const addProduct = () => {
        navigator('/dashboard/parcels/create-parcel');
    };
    const handleAllCheck = (event) => {
        const checked = event.target.checked;
        setAllCheckboxes(checked);
    };
    const info = `Посилки будуть зберігатися на складі безкоштовно протягом 7 днів. Далі вартість зберігання буде коштувати ${prices.storageAfterSevenDays}€ за добу за посилку`;
    return (
        <div>
            {' '}
            <div style={{ display: 'flex' }}>
                <div className={styles.addProduct} onClick={addProduct}>
                    <span className={styles.icon}>
                        <IoIosAddCircleOutline />
                    </span>
                    <div className={styles.addProductText}>Додати посилку</div>
                </div>
                <div className={styles.mobAllCheck}>
                    <p className={styles.chooseAll}>Вибрати усі посилки</p>
                    <input
                        type="checkbox"
                        onChange={handleAllCheck}
                        className={styles.Allcheckbox}
                    />
                </div>
            </div>
            <div className={styles.header}>
                <div>
                    <input
                        type="checkbox"
                        onChange={handleAllCheck}
                        className={sameStyles.checkbox}
                    />
                </div>

                <div className={sameStyles.data}>Дата створення</div>
                <div className={sameStyles.ttn}> ТТН</div>
                <div className={sameStyles.senderNameNp}> Відправник</div>
                <div className={sameStyles.titleNp}> Опис відправлення</div>
                <div className={sameStyles.userComment}>Коментар </div>
                <div className={sameStyles.volumeWeightNp}>Вага</div>
                <div className={sameStyles.announcedPrice}>
                    Оголошена вартість
                </div>
                <div className={sameStyles.statusNp}> Статус</div>
                <div className={sameStyles.dateReceivedNp}> Дата отримання</div>
                <div className={sameStyles.dateCount}>
                    Безкоштовне зберігання до{' '}
                    <span className={styles.hover} data-title={info}>
                        <BsInfoCircle
                            className={styles.infoIcon}
                            style={{
                                fontSize: '16px',
                                backgroundColor: '#EB5D62',
                                borderRadius: '50%',
                            }}
                        />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default HeaderProductList;
