import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import styles from './mainProduct.module.css';
import { useStateContext } from '../../..';
import PageWithProducts from './page with products list/PageWithProducts';
import Loader from '../../singIn_or_logIn/Loader';

const MainProduct = () => {
    const { response, setResponse } = useStateContext();
    const navigator = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const email = localStorage.getItem('email');

    const change = () => {
        navigator('/dashboard/parcels/create-parcel');
    };
    const token =
        'wrYl_zl_8dLXaZul7GcfpqmDqr7jEnli7or_zct_ETxJnOa4ddaEzftNXbuvNSBQn';
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(
                    `https://europe-central2-ukrsklad-4bb35.cloudfunctions.net/product?user_email=${email}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setResponse(res.data.data);
                setLoading(false);
            } catch (error) {
                console.error('Ошибка при выполнении GET-запроса:', error);
                setLoading(false);
                setError(
                    'Під час відправлення запиту трапилась помилка. Спробуйте ще раз пізніше'
                );
            }
        };

        fetchData();
    }, []);
    if (loading) {
        return <Loader />;
    }
    if (error) {
        return <p>{error}</p>;
    }
    return response.length > 0 && response.some((el) => el.isActive) ? (
        <PageWithProducts response={response} />
    ) : (
        <main className={styles.main}>
            <div>
                <img src="../view_2.webp" alt="picture"></img>
            </div>
            <div className={styles.textUps}>
                <p> Упс, у вас ще немає посилок на складі</p>
            </div>
            <div className={styles.divBtn}>
                <button className={styles.btnNoProduct} onClick={change}>
                    {' '}
                    СТВОРИТИ ПОСИЛКУ{' '}
                </button>
            </div>
        </main>
    );
};

export default MainProduct;
