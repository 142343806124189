import React from 'react';
import styles from './eachParcel.module.css';
const TtnInfo = ({ el }) => {
    return (
        <div className={styles.ttnDrop}>
            <span>
                <b>{el.statusUpdateDate.slice(0, 16)}</b>{' '}
            </span>{' '}
            <br />
            <span>{el.npStatus}</span>
        </div>
    );
};

export default TtnInfo;
