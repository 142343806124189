import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { useStateContext } from '../../..';
import LogOutButton from './LogOutBtn';
import styles from './heder.module.css';
import { BiMenu } from 'react-icons/bi';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { mobVisibleSideBar, setMobVisibleSideBar } = useStateContext();
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const handlerMobSideBarButton = () => {
        setMobVisibleSideBar(true);
    };
    const email = localStorage.getItem('email');
    return (
        <header
            className={mobVisibleSideBar ? styles.mobNoHeader : styles.header}
        >
            <div
                className={styles.mobSideBarButton}
                onClick={handlerMobSideBarButton}
            >
                <BiMenu />
            </div>
            <div className={styles.iconAndEmail} onClick={toggleMenu}>
                <span className={styles.iconForEmail}>
                    <img
                        src="/userIcon.png"
                        className={styles.imgLogo}
                        alt="logo"
                    />
                </span>
                <h6 className={styles.email}>{email}</h6>
                <span className={styles.iconDropdown}>
                    {' '}
                    <RiArrowDropDownLine />
                </span>
                {/* Dropdown menu */}
                <div
                    className={`${styles.dropdownMenu} ${
                        isOpen ? styles.openDropdownMenu : ''
                    }`}
                >
                    <div className={styles.line}></div>
                    <div className={styles.feature}>
                        <Link to="/dashboard/profile" className={styles.link}>
                            Профіль
                        </Link>
                    </div>
                    <div className={styles.btnDiv}>
                        <LogOutButton />
                    </div>
                    <div className={styles.line}></div>
                </div>
            </div>
        </header>
    );
};

export default Header;
