import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import styles from './sidebar.module.css';
import { useStateContext } from '../../..';

const SideBar = () => {
    const { mobVisibleSideBar, setMobVisibleSideBar } = useStateContext();

    const location = useLocation();
    const path = location.pathname;

    const navigator = useNavigate();

    const divPress = (url) => {
        navigator(url);
        setMobVisibleSideBar(false);
    };
    const mobCloseIcon = () => {
        setMobVisibleSideBar(false);
    };
    return (
        <aside
            className={mobVisibleSideBar ? styles.mobSidebar : styles.sidebar}
        >
            <figure className={styles.logo}>
                <img
                    src="/l192.png"
                    className={styles.imgMainLogo}
                    alt="logo"
                />
                <figcaption className={styles.textLogo}>Комбосклад</figcaption>
            </figure>
            <div
                onClick={mobCloseIcon}
                className={
                    mobVisibleSideBar
                        ? styles.mobCloseIcon
                        : styles.mobNoCloseIcon
                }
            >
                <MdClose />
            </div>
            <div className={styles.line}></div>

            <nav className={styles.navList}>
                <div
                    className={`${styles.linkDiv} ${
                        path === '/dashboard' ? styles.activeLink : ''
                    }`}
                    onClick={() => divPress('/dashboard')}
                >
                    <img
                        src="/main.png"
                        className={styles.imgLogo}
                        alt="logo"
                    />
                    <span className={styles.link}>ГОЛОВНА</span>
                </div>
                <div className={styles.line}></div>

                <div
                    className={`${styles.linkDiv} ${
                        path === '/dashboard/parcels' ? styles.activeLink : ''
                    }`}
                    onClick={() => divPress('/dashboard/parcels')}
                >
                    <img
                        src="/product.png"
                        className={styles.imgLogo}
                        alt="logo"
                    />
                    <span className={styles.link}>ПОСИЛКИ</span>
                </div>
                <div className={styles.line}></div>
                <div
                    className={`${styles.linkDiv} ${
                        path === '/dashboard/combopacks'
                            ? styles.activeLink
                            : ''
                    }`}
                    onClick={() => divPress('/dashboard/combopacks')}
                >
                    <img
                        src="/combopack.png"
                        className={styles.imgLogo}
                        alt="logo"
                    />
                    <span className={styles.link}>КОМБОПАКИ</span>
                </div>
            </nav>
        </aside>
    );
};

export default SideBar;
