import React from 'react';
import sameStyles from './table.module.css';
import styles from './header.module.css';

const Header = ({ setAllCheckboxes }) => {
    const handleAllCheck = (event) => {
        const checked = event.target.checked;
        setAllCheckboxes(checked);
    };
    return (
        <div>
            <div className={styles.mobAllCheck}>
                <p className={styles.chooseAll}>Вибрати усі посилки</p>
                <input
                    type="checkbox"
                    onChange={handleAllCheck}
                    className={styles.Allcheckbox}
                />
            </div>
            <div className={styles.container}>
                <div>
                    <input
                        type="checkbox"
                        onChange={handleAllCheck}
                        className={sameStyles.checkbox}
                    />
                </div>

                <div className={sameStyles.dateCreating}>Дата створення</div>
                <div className={sameStyles.ttn} style={{ color: 'white' }}>
                    {' '}
                    ТТН
                </div>
                <div className={sameStyles.statusStor}> Статус </div>
                <div className={sameStyles.sender}>
                    {' '}
                    Ваш посередник в Україні
                </div>
                <div className={sameStyles.receiver}>
                    {' '}
                    Кінцевий отримувач в ЄС
                </div>
                <div className={sameStyles.title}>Назва </div>
                <div className={sameStyles.volumeWeightNp}>Вага</div>
                <div className={sameStyles.Price}>
                    Оплачено/ Залишилось оплатити
                </div>
            </div>
        </div>
    );
};

export default Header;
