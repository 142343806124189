import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateContext } from '../../..';
import Loader from '../../singIn_or_logIn/Loader';
import styles from './mainParcel.module.css';
import Header from './Header';
import EachParcel from './EachParcel';
import ButtonPay from './ButtonPay';

const MainParcel = () => {
    const { parcelResponse, setParcelResponse } = useStateContext();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigator = useNavigate();
    const [checkboxes, setCheckboxes] = useState(
        Array(parcelResponse.length).fill(false)
    );
    const {
        chosenParcel,
        setChosenParcel,
        postParcelResponse,
        setPostParcelResponse,
    } = useStateContext();
    const email = localStorage.getItem('email');

    useEffect(() => {
        // Очищаем chosenResponse при смене URL
        setChosenParcel([]);
    }, [navigator, setChosenParcel]);

    const handleCheckboxChange = (index, checked) => {
        const updatedCheckboxes = [...checkboxes];
        updatedCheckboxes[index] = checked;
        setCheckboxes(updatedCheckboxes);
    };
    const handleAllCheck = (checked) => {
        const updatedCheckboxes = Array(parcelResponse.length).fill(checked);
        setCheckboxes(updatedCheckboxes);
        if (checked) {
            setChosenParcel(parcelResponse);
        } else {
            // Если чекбокс "Выделить все" снят, очищаем chosenResponse
            setChosenParcel([]);
        }
    };

    const token =
        'wrYl_zl_8dLXaZul7GcfpqmDqr7jEnli7or_zct_ETxJnOa4ddaEzftNXbuvNSBQn';

    const fetchData = async () => {
        try {
            const res = await axios.get(
                `https://europe-central2-ukrsklad-4bb35.cloudfunctions.net/parcel?user_email=${email}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setParcelResponse(res.data.data);
            setLoading(false);
        } catch (error) {
            console.error('Ошибка при выполнении GET-запроса:', error);
            setLoading(false);
            setError(
                'Під час відправлення запиту трапилась помилка. Спробуйте ще раз пізніше'
            );
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        let interval;
        if (postParcelResponse) {
            let load;
            interval = setInterval(() => {
                fetchData();
                load = parcelResponse.some(
                    (el) => el.id === postParcelResponse.data
                );
                if (!load) {
                    setTimeout(() => setPostParcelResponse(null), 4000);
                    clearInterval(interval);
                }
            }, 2000);
        }
    }, []);
    if (postParcelResponse || loading) {
        return <Loader />;
    }

    if (error) {
        return <p>{error}</p>;
    }
    if (parcelResponse.length === 0) {
        return (
            <main className={styles.main}>
                <div>
                    <img src="../parcel.png" alt="picture"></img>
                </div>
                <div className={styles.textUps}>
                    <p>
                        {' '}
                        У вас ще немає жодного комбопаку. Перейдіть на вкладку
                        <span
                            className={styles.textLink}
                            onClick={() => {
                                navigator('/dashboard/parcels');
                            }}
                        >
                            {' '}
                            "Посилки"
                        </span>{' '}
                        щоб створити комбопак
                    </p>
                </div>
            </main>
        );
    }

    return (
        <div>
            <ButtonPay />
            {chosenParcel.length > 10 ? (
                <span className={styles.more10Parcel}>
                    За один раз можна сплатити не більше 10
                </span>
            ) : null}
            <Header setAllCheckboxes={handleAllCheck} />
            {parcelResponse.map((el, index) => {
                return (
                    <EachParcel
                        el={el}
                        isChecked={checkboxes[index]}
                        onCheckboxChange={(checked) =>
                            handleCheckboxChange(index, checked)
                        }
                        key={el.id}
                    />
                );
            })}
        </div>
    );
};

export default MainParcel;
