import React, { useEffect, useState } from 'react';
import styles from './priceDrop.module.css';

const PriceDrop = ({ el }) => {
    const [arr, setArr] = useState([]);
    useEffect(() => {
        const filteredArr = el.paymentDetails.filter((e) => e.price > 0);
        setArr(filteredArr);
    }, [el]);

    return (
        <div className={styles.background}>
            <div className={styles.header}>
                <div className={styles.name}>Послуга</div>
                <div className={styles.count}> Кількість</div>
                <div className={styles.price}> Ціна</div>
            </div>
            {Array.isArray(el.paymentDetails)
                ? arr.map((e, index) => {
                      return (
                          <div key={index} className={styles.main}>
                              <div className={styles.name}>{e.name}</div>
                              <div className={styles.count}>{e.count}</div>
                              <div className={styles.price}> {e.price} €</div>
                          </div>
                      );
                  })
                : 'трапилась помилка, спробуйте пізніше'}
        </div>
    );
};

export default PriceDrop;
