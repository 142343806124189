import RegComp from './RegComp';

const Main = () => {
    return (
        <>
            <RegComp />
        </>
    );
};

export default Main;
