import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BiChevronLeft } from 'react-icons/bi';
import styles from './mainProduct.module.css';
import nexStyles from './postForm.module.css';
import postRequest from './utils/postRequest';
import { useStateContext } from '../../..';
import SmallLoader from '../../utils/SmallLoader';

const PostForm = () => {
    const { setResponse } = useStateContext();
    const [flag, setFlag] = useState(false);
    const [error, setError] = useState('');
    const [ttn, setTtn] = useState('');
    const [coment, setComent] = useState('');
    const email = localStorage.getItem('email');

    const navigator = useNavigate();

    const handleTtnChange = (e) => {
        const inputText = e.target.value;
        const cleanedTTN = inputText.replace(/[^0-9]/g, '');
        setTtn(cleanedTTN);
    };

    const handleComentChange = (e) => {
        setComent(e.target.value);
    };
    const handleSubmit = async (e) => {
        setFlag(true); // for start Loader component
        e.preventDefault();

        if (ttn && ttn.length === 14) {
            await postRequest(
                ttn,
                email,
                coment,
                setResponse,
                setError,
                navigator
            );
            setFlag(false); // unmount Loader component
        } else if (ttn.length === 0) {
            setFlag(false); // unmount Loader component
            setError('ТТН є обовʼязковим полем для заповнення');
        } else {
            setFlag(false); // unmount Loader component
            setError('ТТН повинен містити 14 цифр');
        }
    };

    const toPreviousPage = () => {
        navigator('/dashboard/parcels');
    };

    return (
        <div className={nexStyles.main}>
            <div onClick={toPreviousPage} className={nexStyles.toPreviousPage}>
                <BiChevronLeft className={nexStyles.icon} />
                <span className={nexStyles.comeBackText}>
                    Повернутися до списку посилок
                </span>
            </div>

            <div className={nexStyles.newProduct}>НОВА ПОСИЛКА</div>
            <form onSubmit={handleSubmit}>
                <div className={styles.formContainer}>
                    <div className={nexStyles.divInput}>
                        <div>
                            <label htmlFor="ttn">ТТН номер*</label>
                        </div>
                        <div>
                            <input
                                value={ttn}
                                inputMode="numeric"
                                placeholder="59000971151783"
                                type="text"
                                id="ttn"
                                className={`${styles.input} ${
                                    ttn.length === 14 ? styles.correctInput : ''
                                }`}
                                onChange={handleTtnChange}
                            />
                        </div>
                    </div>
                    <div className={nexStyles.divInput}>
                        <div>
                            <label htmlFor="coment">Коментар</label>
                        </div>
                        <div>
                            <input
                                value={coment}
                                maxLength={80}
                                placeholder="шкарпетки "
                                type="text"
                                id="coment"
                                className={styles.input}
                                onChange={handleComentChange}
                            />
                        </div>
                    </div>
                </div>
                {error ? (
                    <div className={nexStyles.error}>{error}</div>
                ) : undefined}
                {flag && (
                    <div className={nexStyles.smallLoader}>
                        <SmallLoader />
                    </div>
                )}
                <button type="submit" className={styles.btn}>
                    Cтворити посилку
                </button>
            </form>
        </div>
    );
};

export default PostForm;
