import React, { useState } from 'react';
import axios from 'axios';
import styles from './mainSendComponent.module.css';
import { useStateContext } from '../../..';
import { useNavigate } from 'react-router';
import SmallLoader from '../../utils/SmallLoader';

const ButtonCreateParcel = () => {
    const [textError, setTextError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [apiError, setApiError] = useState('');
    const [postError, setPostError] = useState('');
    const [loader, setLoader] = useState(false);
    const { parcelInfo, setParcelInfo, setPostParcelResponse } =
        useStateContext();
    const { checkCity, checkAddress } = useStateContext();
    const email = localStorage.getItem('email');

    const navigator = useNavigate();
    const token =
        'wrYl_zl_8dLXaZul7GcfpqmDqr7jEnli7or_zct_ETxJnOa4ddaEzftNXbuvNSBQn';

    const sendParcelHendler = async () => {
        setTextError('');
        setPhoneError('');
        setApiError('');
        setPostError('');

        if (
            parcelInfo.agent.Name.length === 0 ||
            parcelInfo.receiver.Name.length === 0 ||
            parcelInfo.receiver.City.length === 0
        ) {
            setTextError('Необхідно заповнити усі поля з відміткою *');
            return;
        }
        if (
            parcelInfo.agent.Phone.length < 9 ||
            parcelInfo.agent.Phone.length > 13 ||
            parcelInfo.receiver.Phone.length < 9 ||
            parcelInfo.receiver.Phone.length > 15
        ) {
            setPhoneError('Некоректно вказанний номер телефону');
            return;
        }

        if (!checkCity || !checkAddress) {
            setApiError(
                'Місто та відділення Нової Пошти необхідно вибрати з випадаючего меню'
            );
        } else {
            setLoader(true);
            try {
                const postResponse = await axios.post(
                    'https://europe-central2-ukrsklad-4bb35.cloudfunctions.net/parcel',
                    {
                        ...parcelInfo,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setPostParcelResponse(postResponse.data);
                navigator('/dashboard/combopacks');
                setParcelInfo({
                    userEmail: email,
                    productsList: [],
                    agent: {
                        Name: '',
                        NovaPoshtaSettlementRef: '',
                        NovaPoshtaWarehouseRef: '',
                        Phone: '',
                    },
                    receiver: {
                        Name: '',
                        City: '',
                        Phone: '',
                        AdditionalText: '',
                    },
                    comment: '',
                });
            } catch (error) {
                setPostError(error.response.data.errorMessage);
            } finally {
                setLoader(false);
            }
        }
    };
    return (
        <>
            <div className={styles.ErrorWithButton}>
                {textError ? (
                    <div className={styles.errorText}>
                        <p>{textError}</p>
                    </div>
                ) : (
                    ''
                )}
                {loader ? (
                    <div className={styles.loader}>
                        <SmallLoader />
                    </div>
                ) : undefined}

                {postError ? (
                    <div
                        className={styles.errorText}
                        style={{ fontSize: '14px' }}
                    >
                        <p>{postError}</p>
                    </div>
                ) : (
                    ''
                )}
                {phoneError ? (
                    <div className={styles.errorText}>
                        <p>{phoneError}</p>
                    </div>
                ) : (
                    ''
                )}
                {apiError ? (
                    <div className={styles.errorText}>
                        <p>{apiError}</p>
                    </div>
                ) : (
                    ''
                )}
                <div className={styles.divBtn}>
                    <button className={styles.btn} onClick={sendParcelHendler}>
                        Створити комбопак
                    </button>
                </div>
            </div>
        </>
    );
};

export default ButtonCreateParcel;
