import { useContext } from 'react';
import { signOut } from 'firebase/auth';
import { Context } from '../../..';
import { useNavigate } from 'react-router-dom';
import styles from './heder.module.css';

function LogOutButton() {
    const { auth } = useContext(Context);
    const navigate = useNavigate();
    const handleLogout = async () => {
        try {
            await signOut(auth);
            navigate('/');
            localStorage.clear();
        } catch (error) {
            console.error('Ошибка при выходе из системы:', error);
        }
    };

    return (
        <button className={styles.sizeBtn} onClick={handleLogout}>
            Вийти
        </button>
    );
}

export default LogOutButton;
